<template>
    <div class="loan-record">
       <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">申请进度详情</div>
       <div class="header">
        <div class="step">
            <div class="icon">
                <img src="../assets/img/jindu1.png" alt="">
            </div>
            <div class="p">需求申请</div>
        </div>
        <div :class="[{ active: approvalDetail.checkStatus ===  '0'|| approvalDetail.checkStatus ===  '1'}, 'step']">
            <div class="icon">
                <img src="../assets/img/jindu2.png" alt="">
            </div>
            <div class="p">待受理</div>
        </div>
        <div :class="[{ active: approvalDetail.checkStatus === '2' || approvalDetail.checkStatus ===  '3'}, 'step']">
            <div class="icon">
                <img src="../assets/img/jindu3.png" alt="">
            </div>
            <div class="p">审核阶段</div>
        </div>
        <div :class="[{ active: approvalDetail.checkStatus === '4'}, 'step']">
            <div class="icon">
                <img src="../assets/img/jindu4.png" alt="">
            </div>
            <div class="p">录入合同阶段</div>
        </div>
        <div :class="[{ active: approvalDetail.checkStatus === '-1'|| approvalDetail.checkStatus ===  '7'}, 'step']">
            <div class="icon">
                <img src="../assets/img/jindu5.png" alt="">
            </div>
            <div class="p">完成</div>
        </div>
    </div>
        <!-- 待受理 -->
        <div class="card" v-if="approvalDetail.checkStatus === '0'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p class="yellow">待受理</p>
                </div>
                <!-- <div class="row">
                    <p>提交时间：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div> -->
            </div>
            <img class="status" src="../assets/img/sqjd0.png" alt="">
        </div>
        <!-- 受理失败 -->
        <div class="card" v-if="approvalDetail.checkStatus === '1'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p class="red">受理失败</p>
                </div>
                <div class="row">
                    <p>受理人：</p>
                    <p>{{approvalDetail.firstCheckName}}</p>
                </div>
                <div class="row">
                    <p>受理时间：</p>
                    <p>{{approvalDetail.firstCheckTime}}</p>
                </div>
                <div class="row">
                    <p>受理说明：</p>
                    <p>{{approvalDetail.firstCheckContent}}</p>
                </div>
                <div class="row">
                    <p>拒绝原因</p>
                    <p> <span v-for="(item,i) in approvalDetail.refusalCauseList" :key="item">{{i+1}}、{{item}}<br></span>
                    </p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd1.png" alt="">
        </div>
        <!-- 待审核 -->
        <div class="card" v-if="approvalDetail.checkStatus === '2'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p><span class="green">受理成功</span>，<span class="yellow">待审核</span></p>
                </div>
                <div class="row">
                    <p>受理人：</p>
                    <p>{{approvalDetail.firstCheckName}}</p>
                </div>
                <div class="row">
                    <p>受理时间：</p>
                    <p>{{approvalDetail.firstCheckTime}}</p>
                </div>
                <div class="row">
                    <p>受理说明：</p>
                    <p>{{approvalDetail.firstCheckContent}}</p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd2.png" alt="">
        </div>
        <!-- 审核不通过 -->
        <div class="card" v-if="approvalDetail.checkStatus === '3'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p><span class="red">审核不通过</span></p>
                </div>
                <div class="row">
                    <p>受理人：</p>
                    <p>{{approvalDetail.firstCheckName}}</p>
                </div>
                <div class="row">
                    <p>受理时间：</p>
                    <p>{{approvalDetail.firstCheckTime}}</p>
                </div>
                <div class="row">
                    <p>受理说明：</p>
                    <p>{{approvalDetail.firstCheckContent}}</p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd3.png" alt="">
        </div>
        <!-- 录入合同 -->
        <div class="card" v-if="approvalDetail.checkStatus === '4'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p><span class="green">受理成功</span>，<span class="green">审核成功</span>，<span class="yellow">录入合同</span></p>
                </div>
                <div class="row">
                    <p>受理人：</p>
                    <p>{{approvalDetail.firstCheckName}}</p>
                </div>
                <div class="row">
                    <p>受理时间：</p>
                    <p>{{approvalDetail.firstCheckTime}}</p>
                </div>
                <div class="row">
                    <p>受理说明：</p>
                    <p>{{approvalDetail.firstCheckContent}}</p>
                </div>
                <div class="row">
                    <p>审核人：</p>
                    <p>{{approvalDetail.secondCheckName}}</p>
                </div>
                <div class="row">
                    <p>审核时间：</p>
                    <p>{{approvalDetail.secondCheckTime}}</p>
                </div>
                <div class="row">
                    <p>审核内容：</p>
                    <p>{{approvalDetail.secondCheckContent}}</p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd4.png" alt="">
        </div>
        <!-- 终止信贷 -->
        <div class="card" v-if="approvalDetail.checkStatus === '-1'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p><span class="red">已终止信贷交互流程</span></p>
                </div>
                <div class="row">
                    <p>终止人：</p>
                    <p>{{approvalDetail.termiName}}</p>
                </div>
                <div class="row">
                    <p>终止时间：</p>
                    <p>{{approvalDetail.termiTime}}</p>
                </div>
                <div class="row">
                    <p>终止原因：</p>
                    <p>{{approvalDetail.termiCause}}</p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd-1.png" alt="">
        </div>
        <!-- 完成 -->
        <div class="card" v-if="approvalDetail.checkStatus === '7'">
            <div class="nums">需求编号：{{approvalDetail.requireId}}</div>
            <div class="company">{{approvalDetail.enterName}}</div>
            <div class="details">
                <div class="row">
                    <p>流程编号：</p>
                    <p>{{approvalDetail.approvalId}}</p>
                </div>
                <div class="row">
                    <p>申请机构：</p>
                    <p>{{approvalDetail.bankName}}</p>
                </div>
                <div class="row">
                    <p>申请人：</p>
                    <p>{{approvalDetail.applicationUserDescr}}</p>
                </div>
                <div class="row">
                    <p>状态：</p>
                    <p><span class="green">已录入授信合同</span></p>
                </div>
            </div>
            <img class="status" src="../assets/img/sqjd7.png" alt="">
        </div>

  
    </div>
</template>
<script>
import { Toast } from 'vant';
import { useRouter } from 'vue-router';
import { ref,reactive,toRefs } from 'vue';
import { apiShowApprovalDetail } from '../api/axios';
export default {
    
    setup() {
        let router = useRouter();
        let aId = router.currentRoute.value.query.approvalId;
        // console.log(router.currentRoute.value.query.approvalId,999);
        const state = reactive({
            approvalDetail: {},
            refusalCauseList: []
        });
        apiShowApprovalDetail({approvalId: aId}).then(res => {                
             if(res.code == '1'){                
                state.approvalDetail = res.info.approvalDetail;
                // state.refusalCauseList = res.info.refusalCauseList;
                // console.log(111)
              }
              else {
                Toast(res.msg);
              }
          });
        // const steps = ref('2');
        // const step = (i) => {
        //     steps.value = i;    
        // }
        return {
            ...toRefs(state),

        }
    },
    methods:{
        goBack(){
            this.$router.go(-1);
        },
    },
}
</script>
<style lang="less" scoped>
.loan-record {
    padding-top: 12.5vw;
    .yellow {
        color: #ff9900;
    }
    .red {
        color: red;
    }
    .green {
        color: #66cc00;
    }
    .header {
    margin: 4vw;
    background-color: #fff;
    border-radius: 1.5vw;
    padding: 4vw;
    display: flex;
    justify-content: space-between;
    .step {
        width: 13vw;
        .icon {
            width: 100%;
            height: 13vw;
            background-color: #cccccc;
            border-radius: 50%;
            text-align: center;
            line-height: 13vw;
            box-sizing: border-box;
            img {
                transform: scale(0.5);
            }
        }
        .p {
            color: #666666;
            font-size: 3.1vw;
            text-align: center;
            margin-top: 2vw;
        }
    }
    .active {
        .icon {
            background-color: #ff9900;
        }
        .p {
            color: #ff9900;
        }
    }
} 
    .card {
        position: relative;
        .nums {
            display: inline-block;
            color: #ff9900;
            border: 1px solid #ff9900;
            border-radius: 1vw;
            font-size: 4vw;
            padding: 0 2vw;
        }
        .company {
            color: #6699ff;
            font-size: 4.8vw;
            font-weight: 500;
            padding-top: 2vw;
        }
        .details {
            width: 100%;            
            padding: 2vw 0;
            .row {
                margin: 1.5vw 0;
                font-size: 3.5vw;
                color: #666666;
                display: flex;
                align-items: baseline;
                p:first-child {
                    width: 23%;
                }
                p:last-child {
                    width: 77%;
                }
            }
            
        }
        .break {
            display: inline-flex;
            align-items: center;
            background: linear-gradient(to right, #ff6532, #ff0201);
            color: #fff;
            font-size: 4vw;
            padding: 2vw 4vw;
            border-radius: 4vw;
            img {
                height: 4.5vw;
                margin-right: 2vw;
            }
            p {
                line-height: 4vw;
            }
        }
        img.status {
            width: 19.5vw;
            height: auto;
            position: absolute;
            right: 0;
            top: 0;
        }

    }
}
</style>